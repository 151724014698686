import * as React from 'react';
import classNames from 'classnames';
import {
  customCssClasses,
  getAriaAttributes,
} from '@wix/editor-elements-common-utils';
import { INativeInputProps } from '../../ComboBoxInput.types';
import { Arrow } from '../Arrow/Arrow';
import semanticClassNames from '../../ComboBoxInput.semanticClassNames';

const NativeInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  INativeInputProps
> = (props, ref) => {
  const {
    className,
    styles,
    id,
    placeholder,
    onFocus,
    onBlur,
    onMouseDown,
    onKeyDown,
    disabled,
    required,
    value,
    filterQuery,
    isOpen,
    setWrapperRef,
    onClick,
    ariaAttributes,
    onInput,
    isValid,
    shouldShowValidityIndication,
    options,
  } = props;

  const getValue = () => {
    return filterQuery || options.find(option => option.value === value)?.text;
  };

  return (
    <div ref={setWrapperRef} className={styles.selectorWrapper}>
      <input
        className={classNames(customCssClasses(semanticClassNames.input), {
          [className]: true,
          [styles.invalid]: shouldShowValidityIndication && !isValid,
        })}
        aria-invalid={shouldShowValidityIndication && !isValid}
        onClick={onClick}
        onKeyDown={onKeyDown}
        placeholder={placeholder.text}
        value={getValue()}
        disabled={disabled}
        required={required}
        onMouseDown={onMouseDown}
        type="text"
        role="combobox"
        {...getAriaAttributes(ariaAttributes)}
        aria-required={required}
        aria-disabled={disabled}
        aria-autocomplete="list"
        aria-expanded={isOpen}
        aria-controls={id}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
      />
      <Arrow styles={styles} isOpen={isOpen} />
    </div>
  );
};

export default React.forwardRef(NativeInput);
